import React from 'react';
import EditItem from "./EditItem";

class Finance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {financeData: JSON.parse(JSON.stringify(props.financeData))};
        this.updateFinanceData = props.updateFinanceData;
    }

    changeValue(name, e) {
        if (!name) return;
        if (!this.state.financeData[name]) return;

        let newData = {...this.state.financeData};
        newData[name].amount = parseInt(e.target.value) || 0;
        this.setState({...this.state, financeData: newData});
    }

    save() {
        var tmp = JSON.parse(JSON.stringify(this.state.financeData))
        fetch('/api/finance', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(tmp)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({...this.state, financeData: result});
                    this.updateFinanceData(result); // Update data app-wide
                    document.getElementById("toastText").innerHTML = "Opgeslagen...";
                    //new bootstrap.Toast(document.getElementById('savedToast')).show(); // TODO - dit compileert niet
                },
                (error) => {
                    console.error(error);
                    this.setState({...this.state, financeData: {}});
                }
            )
    }

    render() {
        const d = this.state.financeData;
        let sortedKeys = [];
        for (let item in d) sortedKeys.push([item, d[item].order]);
        sortedKeys.sort((a, b) => a[1] - b[1]);
        sortedKeys = sortedKeys.map(i => i[0]);

        return (
            <div className="container-sm">
                <br/>

                <table className="table table-borderless table-striped">
                    <thead>
                    <tr>
                        <th className="text-start">Veld</th>
                        <th className="text-start">Waarde</th>
                    </tr>
                    </thead>

                    <tbody>
                    {sortedKeys.map(
                        name => <EditItem key={name} name={name} object={d[name]}
                                          changeValue={this.changeValue.bind(this)}/>
                    )}

                    <tr>
                        <td>&nbsp;</td>
                        <td className="text-end"><input type="button" defaultValue="Opslaan"
                                                        onClick={this.save.bind(this)}/></td>
                    </tr>
                    </tbody>
                </table>

                <div className="position-fixed top-0 start-0 ms-2 mt-2" style={{zIndex: 11}}>
                    <div id="savedToast" className="toast text-white bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex">
                            <div id="toastText" className="toast-body"></div>
                            <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Finance;