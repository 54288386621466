import React from 'react';

class Finance extends React.Component {
    constructor(props) {
        super(props);
        this.financeData = props.financeData;
    }

    render(a) {
        const d = this.financeData;
        return (
            <div className="container-sm">
                <br/>

                <table className="table table-borderless table-striped">
                    <thead>
                    <tr>
                        <th scope="col" className="text-start" colSpan="3">Totalen</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>Beschikbare ruimte</td>
                        <td>Lopende rekeningen min kortlopende schulden</td>
                        <td className='euro total'>{this.num(this.tot('beschikbareRuimte'))}</td>
                    </tr>
                    <tr>
                        <td>Liquide middelen</td>
                        <td>Beschikbaar geld min kortlopende leningen</td>
                        <td className='euro total'>{this.num(this.tot('liquideMiddelen'))}</td>
                    </tr>
                    <tr>
                        <td>Vermogen</td>
                        <td>Vermogen zonder woning</td>
                        <td className='euro total'>{this.num(this.tot('vermogen'))}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Vermogen met woning</td>
                        <td className='euro total'>{this.num(this.tot('vermogenMetWoning'))}</td>
                    </tr>
                    <tr className="spacer">
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>

                    <thead>
                    <tr>
                        <th scope="col" className="text-start" colSpan="3">Vermogen</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>Lopende rekeningen</td>
                        <td>En/Of</td>
                        <td className='euro'>{this.num(d.enof.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Zakelijk Future Software</td>
                        <td className='euro'>{this.num(d.zakelijkFuturesoftware.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Zakelijk Renske Dragt</td>
                        <td className='euro'>{this.num(d.zakelijkRenskedragt.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className='euro total'>{this.num(this.tot('totaalLopend'))}</td>
                    </tr>
                    <tr className="spacer">
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <td>Spaargeld</td>
                        <td>Hypotheek rekening</td>
                        <td className='euro'>{this.num(d.spaarHypo.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Investeringsrekening</td>
                        <td className='euro'>{this.num(d.spaarInvest.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className='euro total'>{this.num(this.tot('totaalSpaar'))}</td>
                    </tr>
                    <tr className="spacer">
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <td>Reserveringen</td>
                        <td>Buffer zakelijk</td>
                        <td className='euro'>{this.num(d.zakelijkBuffer.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Reservering inkomstenbelasting</td>
                        <td className='euro'>{this.num(d.zakelijkBelasting.amount)}</td>
                    </tr>
                    <tr className="borderless">
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className='euro total'>{this.num(this.tot('totaalReserveringen'))}</td>
                    </tr>
                    <tr className="spacer">
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>

                    <tbody>
                    <tr>
                        <td>Investeringen</td>
                        <td>Vastgoed</td>
                        <td className='euro'>{this.num(d.nlinvesteert.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Crypto</td>
                        <td className='euro'>{this.num(d.crypto.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Bitstamp</td>
                        <td className='euro'>{this.num(d.bitstamp.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Alphatron Signage</td>
                        <td className='euro'>{this.num(d.alphatronSignage.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Woning</td>
                        <td className='euro'>{this.num(d.woning.amount)}</td>
                    </tr>
                    <tr className="borderless">
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className='euro total'>{this.num(this.tot('totaalInvesteringen'))}</td>
                    </tr>
                    <tr className="spacer">
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>

                    <thead>
                    <tr>
                        <th scope="col" className="text-start" colSpan="3">Schulden</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>Belastingen</td>
                        <td>Schatting inkomstenbelasting en premies</td>
                        <td className='euro'>{this.num(d.inkomstenbelasting.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>BTW schulden</td>
                        <td className='euro'>{this.num(d.btw.amount)}</td>
                    </tr>
                    <tr>
                        <td>Hypotheken</td>
                        <td>ASR</td>
                        <td className='euro'>{this.num(d.hypotheek.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>Ouders</td>
                        <td className='euro'>{this.num(d.hypotheekOuders.amount)}</td>
                    </tr>
                    <tr>
                        <td>Leningen</td>
                        <td>Zakelijke lening</td>
                        <td className='euro'>{this.num(d.zakelijkeLeningen.amount)}</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td className='euro total'>{this.num(this.tot('totaalSchulden'))}</td>
                    </tr>
                    </tbody>

                </table>

            </div>
        );
    }

    num(amount) {
        // Format the amount
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    tot(name) {
        const d = this.financeData;
        switch (name) {
            case 'totaalLopend':
                return d.enof.amount + d.zakelijkFuturesoftware.amount + d.zakelijkRenskedragt.amount;
            case 'totaalSpaar':
                return d.spaarHypo.amount + d.spaarInvest.amount;
            case 'totaalReserveringen':
                return d.zakelijkBuffer.amount + d.zakelijkBelasting.amount;
            case 'totaalInvesteringen':
                return d.nlinvesteert.amount + d.crypto.amount + d.bitstamp.amount + d.woning.amount + d.alphatronSignage.amount;
            case 'totaalSchulden':
                return d.btw.amount + d.inkomstenbelasting.amount + d.hypotheek.amount + d.hypotheekOuders.amount + d.zakelijkeLeningen.amount;

            case 'beschikbareRuimte':
                return this.tot('totaalLopend') - d.btw.amount;
            case 'liquideMiddelen':
                return this.tot('totaalLopend') + this.tot('totaalSpaar') + this.tot('totaalReserveringen') - d.inkomstenbelasting.amount - d.btw.amount;
            case 'vermogen':
                return this.tot('totaalLopend') + this.tot('totaalSpaar') + this.tot('totaalReserveringen') + this.tot('totaalInvesteringen') - d.woning.amount - (this.tot('totaalSchulden') - d.hypotheek.amount - d.hypotheekOuders.amount);
            case 'vermogenMetWoning':
                return this.tot('totaalLopend') + this.tot('totaalSpaar') + this.tot('totaalReserveringen') + this.tot('totaalInvesteringen') - this.tot('totaalSchulden');

            default:
                return 0;

        }
    }
}

export default Finance;