function NotLoggedin({ isLoggedin }) {
    const alertType = isLoggedin ? "alert-danger" : "alert-warning";
    const errorMsg = isLoggedin ? "Geen toegang" : "Niet ingelogd";
    return (
        <div className="container-fluid mt-3">
            <div className={"alert " + alertType} role="alert">
                <h4 className="alert-heading">{errorMsg}</h4>
                <hr/>
                <p>Login met een geldig account om het financiële overzicht te kunnen inzien.</p>
                <p>Inloggen doe je door op de knop inloggen te klikken in het menu. Gebruik je Google account.</p>
            </div>
        </div>
    );
}

export default NotLoggedin;