function Navbar({isLoggedin, isLoaded, editMode, login, logout, toggleEditMode}) {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <h5>Financiëel overzicht</h5>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                    <form className="d-flex justify-content-end">
                        {
                            isLoggedin && isLoaded &&
                            <>
                            <button className="btn btn-outline-success" type="button"
                                    onClick={toggleEditMode}>{editMode ? "overzicht" : "aanpassen"}</button>
                            &nbsp;
                            </>
                        }
                        {
                            isLoggedin &&
                                <button className="btn btn-outline-success" type="button" onClick={logout}>uitloggen</button>
                        }
                        {
                            !isLoggedin &&
                            <button className="btn btn-outline-success" type="button" onClick={login}>inloggen</button>
                        }
                    </form>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;