import React from 'react';
import './App.css';

import Navbar from './components/Navbar';
import Finance from './components/Finance';
import Editor from './components/Editor';
import NotLoggedin from './components/NotLoggedin';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            isLoggedin: false,
            isLoaded: false,
            error: null,
            data: {}
        }
    }

    componentDidMount() {
        this.loadFinanceData();
    }

    login() {
        window.location.href = '/login';
    }

    logout() {
        window.location.href = '/logout';
    }

    toggleEditMode() {
        this.setState({...this.state, editMode: !this.state.editMode})
    }

    loadFinanceData() {
        const self = this;
        fetch('/api/finance')
            .then(res => {
                if (!res.ok) throw res; // Throw response as error
                return res.json()
            })
            .then(result => {
                self.setState({
                    ...this.state,
                    isLoggedin: true,
                    isLoaded: true,
                    error: null,
                    data: result
                });
            })
            .catch(error => {
                self.setState({
                    ...this.state,
                    isLoggedin: error.status && error.status === 403, // true on a http:403
                    isLoaded: false,
                    error: error,
                    data: {}
                });
            });
    }

    updateFinanceData(newData) {
        this.setState({
            ...this.state,
            data: newData
        });
    }

    render() {
        let s = this.state;
        return (<div className="App">
            <Navbar isLoggedin={s.isLoggedin} isLoaded={s.isLoaded} editMode={s.editMode} login={this.login} logout={this.logout} toggleEditMode={this.toggleEditMode.bind(this)} />
            { s.isLoggedin && s.isLoaded && !s.editMode && <Finance financeData={s.data} /> }
            { s.isLoggedin && s.isLoaded && s.editMode && <Editor financeData={s.data} updateFinanceData={this.updateFinanceData.bind(this)} /> }
            { (!s.isLoggedin || !s.isLoaded) && <NotLoggedin isLoggedin={s.isLoggedin} /> }
        </div>);
    }
}

export default App;